<template>
  <div class="sign">
    <div :class="{ 'btn': width <= 750, 'bottom-btn': width > 750 }">
      <div :class="{ 'cancel-btn': width <= 750, 'bottom-cancel-btn': width > 750 }" @click="clear">
        重写
      </div>
      <div :class="{ 'save-btn': width <= 750, 'bottom-save-btn': width > 750 }" class="" @click="save">
        保存
      </div>
    </div>
    <vue-esign
      ref="esign"
      :class="{ 'canvas': width <= 750, 'bottom-canvas': width > 750 }"
      :width="canvasWidth"
      :height="canvasHeight"
      bgColor="#FFFFFF"
    />
  </div>
</template>

<script>
import vueEsign from 'vue-esign'
import uuid from 'uuid'
import {Toast} from 'vant'
import {uploadFile} from "@/request/api/contract";
export default {
  name: 'Signature',
  components: { vueEsign },
  data() {
    return {
      width: 0,
      height: 0
    }
  },
  created() {
    this.initSize()
    window.onresize = () => {   //屏幕尺寸变化就重新赋值
      return (() => {
        this.initSize()
      })()
    }
  },
  activated() {
    this.$refs.esign.reset()
  },
  computed: {
    canvasWidth() {
      const size = this.width > 750 ? 20 : 70
      return this.width - size
    },
    canvasHeight() {
      const size = this.width > 750 ? 70 : 20
      return this.height - size
    }
  },
  methods: {
    initSize() {
      this.width = (window.innerWidth || document.body.clientWidth)
      this.height = (window.innerHeight || document.body.clientHeight)
    },
    clear() {
      this.$refs.esign.reset()
    },
    save() {
      this.$refs.esign
        .generate() // 使用生成器调用把签字的图片转换成为base64图片格式
        .then((res) => {
          if (this.width > 750) {
            this.uploadSignature(res)
          } else {
            this.rotateBase64Img(res, 270, (base64data) => {
              this.uploadSignature(base64data)
            })
          }
        })
        .catch(() => {
          // 画布没有签字时会执行这里提示一下
          Toast.fail({
            message: '请签名后再生成签字图片'
          })
        })
    },
    uploadSignature(base64data) {
      const file = this.dataURLtoFile(base64data, uuid.v1() + '_signature.png')
      const formData = new FormData();
      formData.append('files', file)
      uploadFile(formData).then(data => {
        if (data && data.length > 0) {
          this.$bus.$emit('onSignatureSuccess', (data[0]))
          this.$router.back()
        }
      })
    },
    rotateBase64Img(src, edg, callback) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      let imgW; // 图片宽度
      let imgH; // 图片高度
      let size; // canvas初始大小
      (edg < 0) && (edg = (edg % 360) + 360)
      const quadrant = (edg / 90) % 4; //旋转象限
      const cutCoor = {sx: 0, sy: 0, ex: 0, ey: 0}; //裁剪坐标
      var image = new Image();
      image.crossOrigin = "anonymous"
      image.src = src;
      image.onload = function () {
        imgW = image.width;
        imgH = image.height;
        size = imgW > imgH ? imgW : imgH;
        canvas.width = size * 2;
        canvas.height = size * 2;
        switch (quadrant) {
          case 0:
            cutCoor.sx = size;
            cutCoor.sy = size;
            cutCoor.ex = size + imgW;
            cutCoor.ey = size + imgH;
            break;
          case 1:
            cutCoor.sx = size - imgH;
            cutCoor.sy = size;
            cutCoor.ex = size;
            cutCoor.ey = size + imgW;
            break;
          case 2:
            cutCoor.sx = size - imgW;
            cutCoor.sy = size - imgH;
            cutCoor.ex = size;
            cutCoor.ey = size;
            break;
          case 3:
            cutCoor.sx = size;
            cutCoor.sy = size - imgW;
            cutCoor.ex = size + imgH;
            cutCoor.ey = size + imgW;
            break;
        }
        ctx.translate(size, size);
        ctx.rotate(edg * Math.PI / 180);
        ctx.drawImage(image, 0, 0);
        const imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
        if (quadrant % 2 === 0) {
          canvas.width = imgW;
          canvas.height = imgH;
        } else {
          canvas.width = imgH;
          canvas.height = imgW;
        }
        ctx.putImageData(imgData, 0, 0);
        callback(canvas.toDataURL())
      }
    },
    //将base64转换为blob
    dataURLtoBlob(dataUrl) {
      let arr = dataUrl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    //将blob转换为file
    blobToFile(theBlob, fileName){
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return theBlob;
    },
    //将base64转换为文件
    dataURLtoFile(dataUrl, filename) {
      let arr = dataUrl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
  }
}
</script>

<style scoped>
.sign {
  position: relative;
  overflow: hidden;
  background-color: #FFFFFF;
  height: 100vh;
  width: 100vw;
}
.canvas {
  background-color: #FFFFFF;
  position: absolute;
  z-index: 9999;
  left: 45px;
  border: 1px dotted black;
  margin: 10px;
}
.bottom-canvas {
  background-color: #FFFFFF;
  position: absolute;
  z-index: 9999;
  border: 1px dotted black;
  margin: 10px;
}
.btn {
  height: 100vh;
  position: fixed;
  font-size: 16px;
}
.bottom-btn {
  height: 50px;
  width: 100vw;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
}
.cancel-btn {
  width: 42vh;
  position: fixed;
  left: 27px;
  border: 1px solid #a9a1a1;
  transform: rotate(90deg);
  color: #666;
  margin-left: -21vh;
  margin-top: 24vh;
  height: 32px;
  line-height: 32px;
  border-radius: 3px;
  text-align: center;
  justify-content: center;
}
.bottom-cancel-btn {
  width: 42vw;
  height: 32px;
  border: 1px solid #a9a1a1;
  color: #666;
  line-height: 32px;
  text-align: center;
  border-radius: 3px;
}
.save-btn {
  position: fixed;
  margin-top: 71vh;
  margin-left: -21vh;
  transform: rotate(90deg);
  background: #FFC200;
  width: 42vh;
  left: 27px;
  border-radius: 3px;
  border: 1px solid #FFC200;
  color: #fff;
  height: 32px;
  line-height: 32px;
  text-align: center;
  justify-content: center;
}
.bottom-save-btn {
  width: 42vw;
  height: 32px;
  border: 1px solid #FFC200;
  background: #FFC200;
  color: #fff;
  line-height: 32px;
  text-align: center;
  border-radius: 3px;
}
</style>
