<template>
  <div></div>
</template>

<script>
export default {
  name: "index",
  created() {
    const code = this.$route.query.code
    // if (!code || code.length <= 0) return
    this.getOpenId(code)
  },
  methods: {
    getOpenId(code) {
      alert(code || '未找到code')
    }
  }
}
</script>

<style scoped>

</style>
